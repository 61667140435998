import React, { useContext } from "react";
import { GlobalDataContext } from "../../../context/context";
import TransparentHeader from "../TransparentHeader";
import BaseLayout from "../BaseLayout";

function Socialmedia() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Social Media">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Social Media"
          Subheader="Social Media"
          bgimg={`${rpdata?.stock?.[2]}`}
        // urlVideo={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Electric.mp4?alt=media&token=1f5f7df4-e652-4708-b766-b7644ee1702c"}
        />
        <div className="h-auto py-20 flex items-center justify-center">
          <div className={`w-4/5 mx-auto flex flex-wrap justify-center items-center gap-10 p-10`}>
            {
              rpdata?.dbSocialMedia?.redes.length > 0 ?
                rpdata?.dbSocialMedia?.redes.map((item, index) => {
                  return (
                    <div
                      className="text-center content-directorios"
                      key={index}
                      data-aos="zoom-in"
                      data-aos-duration="1500"
                    >
                      <a href={item.url} target="_blank" rel='noopener noreferrer'>
                        <img src={item.image} alt="Not Found" width={'250px'} />
                      </a>
                    </div>
                  );
                }) : null
            }
          </div>
        </div>

      </div>
    </BaseLayout>
  );
}

export default Socialmedia;
